<template>
  <div class="header bg-color">
    <div class="site-meta">
      <a href="/">小黑</a>
      <p class="mt10">精于心，简于形</p>
    </div>
    <div class="site-nav">
      <ul>
        <li>
          <a href=""><i class="fa fa-home"></i>首页</a>
        </li>
        <li>
          <a href=""><i class="fa fa-archive"></i>归档</a>
        </li>
        <li>
          <a href=""><i class="fa fa-tags"></i>标签</a>
        </li>
        <li>
          <a href=""><i class="fa fa-user"></i>关于</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.header {
  position: absolute;
  top: 0;
  border-radius: 5px;
  width: 240px;
  overflow: hidden;
  .site-meta {
    background-color: #000;
    text-align: center;
    padding: 20px 0;
    font-family: "Rosario", "Monda", "PingFang SC", "Microsoft YaHei",
      sans-serif;
    a {
      font-size: 24px;
      color: white;
      font-weight: bold;
    }
    p {
      font-size: 13px;
      color: #ddd;
    }
  }
  .site-nav {
    padding: 20px 0;
    li {
      line-height: 32px;
      a {
        display: block;
        font-size: 13px;
        line-height: inherit;
        position: relative;
        box-sizing: border-box;
        line-height: inherit;
        border: none;
        padding: 5px 20px;
        text-align: left;
        i{
          margin-right: 5px;
        }
      }
    }
  }
}
</style>