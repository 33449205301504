<template>
  <div class="flex">
    <Sidebar />
    <div class="main-container bg-color it1">
      <div class="article" v-for="item in articleList" :key="item">
        <ArticleHeader
          :title="item.title"
          :addTime="item.add_time"
          :tag="item.tag"
          :read_tim="item.read_tim"
          :words="item.words"
        />
        <div class="content">
          <p>{{ item.desc }}</p>
          <div class="img"><img :src="item.cover" v-if="item.cover" /></div>
        </div>
        <div class="post-button">
          <a class="btn" :href="`/article?id=${item.id}`">阅读全文 »</a>
        </div>
        <div class="end"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/sidebar";
import ArticleHeader from "@/components/articleHeader";
import axios from "axios";
export default {
  name: "Home",
  components: { Sidebar, ArticleHeader },
  data() {
    return {
      articleList: [
        {
          id: 1,
          title: "Git 修改 commit 的历史作者信息",
          add_time: "2021-04-10",
          tag: "Git",
          read_time: "2",
          words: "435",
          desc:
            "有时候在另一台电脑忘了改 address 信息直接 commit 时候。结果发现 commit 的不是自己的 address 信息，可以参加以下方法。",
          cover: require("@/assets/images/7wmlXmv8Y.png"),
        },
        {
          id: 2,
          title: "取消Vue-cli的eslint的严格模式",
          add_time: "2021-04-10",
          tag: "Git",
          read_time: "2",
          words: "435",
          desc:
            "当我们使用vue-cli默认创建项目的，会直接按照默认安装了eslint了，刚开始觉得还好，有个限制也能规范一点，就没管了。但是写的时候会遇到一个很头疼的问题，就是你先定义好一个变量，后面再拿来用，但是eslint不应许你这样，你定义了必须现在用😤😤！！！如下图",
          cover: require("@/assets/images/93WmNaqGa.png"),
        },
        {
          id: 3,
          title: "nav菜单横向滚动隐藏滚动条",
          add_time: "2021-04-10",
          tag: "Git",
          read_time: "2",
          words: "435",
          desc:
            "当我们移动端nav菜单很多时候，需要实现滚动效果。使用 overflow-x: scroll 会出现滚动条，可以使用以下方法将滚动条隐藏。",
          cover: "",
        },
      ],
    };
  },
  created() {
    axios
      .get("https://www.wrh.plus/lbg_admin/index/index/hello", {
        params: {
          name: "lbg hello",
        },
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  border-radius: 5px;
  padding: 40px;
  line-height: 2;
  .article {
    font-family: "Monda", "PingFang SC", "Microsoft YaHei", sans-serif;
    .content {
      text-align: left;
      .img {
        text-align: center;
        img {
          max-width: 100%;
        }
      }
    }
    .post-button {
      text-align: center;
      margin-top: 50px;
      .btn {
        display: inline-block;
        line-height: 2;
        padding: 0 20px;
        font-size: 14px;
        color: #666;
        background: #fff;
        border: 2px solid #555 !important;
        text-decoration: none;
        border-radius: 0;
        transition-property: background-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;
        transition-delay: 0s;
      }
      .btn:hover {
        color: #fff;
        background: #333;
      }
    }
    .end {
      display: block;
      margin: 80px auto 60px;
      width: 8%;
      height: 1px;
      background: #ccc;
      text-align: center;
    }
  }
}
</style>
