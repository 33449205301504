<template>
  <dic class="flex">
    <Sidebar />
    <div class="main-container bg-color it1">
      <ArticleHeader
        :title="articleInfo[id - 1].title"
        :addTime="articleInfo[id - 1].add_time"
        :tag="articleInfo[id - 1].tag"
        :read_tim="articleInfo[id - 1].read_tim"
        :words="articleInfo[id - 1].words"
      />
      <div v-html="articleInfo[id - 1].content"></div>
    </div>
  </dic>
</template>
<script>
import "@/assets/css/markdown.scss";
import Sidebar from "@/components/sidebar";
import ArticleHeader from "@/components/articleHeader";
export default {
  components: { Sidebar, ArticleHeader },
  data() {
    return {
      id: 1,
      articleInfo: [
        {
          title: "Git 修改 commit 的历史作者信息",
          add_time: "2021-04-10",
          tag: "Git",
          read_time: "2",
          words: "435",
          content: `<div class="post-body next-md-body" id="post_body">
                <p>有时候在另一台电脑忘了改 <strong>address</strong> 信息直接 <strong>commit</strong> 时候。结果发现 <strong>commit</strong> 的不是自己的 <strong>address</strong> 信息，可以参加以下方法。</p>
<!-- more -->
<h2 id="修改-git-配置">修改 Git 配置</h2>
<pre><code class="hljs lua">git <span class="hljs-built_in">config</span> <span class="hljs-comment">--global user.email "youremail@googl.com"</span>
git <span class="hljs-built_in">config</span> <span class="hljs-comment">--global user.name "your name"</span>
</code></pre>
<p>这样修改 git 配置后只对以后的 <strong>commit</strong> 起效，如果想修改历史的 <strong>address</strong>，GitHub给出了官方指南：<a href="https://docs.github.com/en/github/using-git/changing-author-info" target="_blank">Changing author info</a></p>
<table><tbody><tr><td bgcolor="#ffdce0">
<p><strong>Warning</strong>: This action is destructive to your repository's history. If you're collaborating on a repository with others, it's considered bad practice to rewrite published history. You should only do this in an emergency.</p>
<p><strong>警告</strong>：此操作会破坏您存储库的历史记录。如果您正在与其他人在存储库上进行协作，则重写已发布的历史记录被视为不好的做法。您仅应在紧急情况下这样做。</p>
</td></tr></tbody></table>
<h2 id="准备">准备</h2>
<ul>
<li>您要更改的作者/提交者字段中显示的旧电子邮件地址</li>
<li>您希望将此类提交归因于的正确名称和电子邮件地址</li>
</ul>
<h2 id="操作步骤">操作步骤</h2>
<ol>
<li>
<p>打开Git Bash。</p>
</li>
<li>
<p>创建存储库的全新裸克隆：</p>
<pre><code class="hljs vim">git clone --bare http<span class="hljs-variable">s:</span>//github.<span class="hljs-keyword">com</span>/user/repo.git
<span class="hljs-keyword">cd</span> repo.git
</code></pre>
</li>
<li>
<p>复制并粘贴脚本，根据您收集的信息替换以下变量：</p>
<ul>
<li>OLD_EMAIL</li>
<li>CjsORRECT_NAME</li>
<li>CORRECT_EMAIL</li>
</ul>
<pre><code class="hljs makefile"><span class="hljs-comment">#!/bin/sh</span>

git filter-branch --env-filter '

OLD_EMAIL=<span class="hljs-string">"your-old-email@example.com"</span>
CORRECT_NAME=<span class="hljs-string">"Your Correct Name"</span>
CORRECT_EMAIL=<span class="hljs-string">"your-correct-email@example.com"</span>

if [ <span class="hljs-string">"$GIT_COMMITTER_EMAIL"</span> = <span class="hljs-string">"$OLD_EMAIL"</span> ]
then
<span class="hljs-keyword">export</span> GIT_COMMITTER_NAME=<span class="hljs-string">"$CORRECT_NAME"</span>
<span class="hljs-keyword">export</span> GIT_COMMITTER_EMAIL=<span class="hljs-string">"$CORRECT_EMAIL"</span>
fi
if [ <span class="hljs-string">"$GIT_AUTHOR_EMAIL"</span> = <span class="hljs-string">"$OLD_EMAIL"</span> ]
then
<span class="hljs-keyword">export</span> GIT_AUTHOR_NAME=<span class="hljs-string">"$CORRECT_NAME"</span>
<span class="hljs-keyword">export</span> GIT_AUTHOR_EMAIL=<span class="hljs-string">"$CORRECT_EMAIL"</span>
fi
' --tag-name-filter cat -- --branches --tags
</code></pre>
</li>
<li>
<p>按Enter键运行脚本。</p>
</li>
<li>
<p>查看新的Git历史记录是否有错误。</p>
</li>
<li>
<p>将更正的历史记录推送到GitHub：</p>
<pre><code class="hljs nginx"><span class="hljs-attribute">git</span> push --force --tags origin <span class="hljs-string">'refs/heads/*'</span>   //强制推送
</code></pre>
</li>
<li>
<p>清理临时克隆：</p>
<pre><code class="hljs properties"><span class="hljs-attr">cd</span> <span class="hljs-string">..</span>
<span class="hljs-attr">rm</span> <span class="hljs-string">-rf repo.git</span>
</code></pre>
</li>
</ol>

              </div>`,
        },
        {
          title: "取消Vue-cli的eslint的严格模式",
          add_time: "2021-04-10",
          tag: "Git",
          read_time: "2",
          words: "435",
          content:`<p>
  当我们使用vue-cli默认创建项目的，会直接按照默认安装了eslint了，刚开始觉得还好，有个限制也能规范一点，就没管了。但是写的时候会遇到一个很头疼的问题，就是你先定义好一个变量，后面再拿来用，但是eslint不应许你这样，你定义了必须现在用😤😤！！！如下图
</p>
<!-- more -->
<figure data-type="image" tabindex="1"><img src="@/assets/images/1587404257570.png" alt=""
    loading="lazy"></figure>
<p>但你一定要先定义后使用怎么办？？？</p>
<p>你可以使用以下方法关掉这个规则验证的，只要在package.json的eslintConfig里面的规则加上<code>"no-unused-vars":"off"</code></p>
<figure data-type="image" tabindex="2"><img src="@/assets/images/1587405026600.png" alt=""
    loading="lazy"></figure>`
        },
        {
          title: "nav菜单横向滚动隐藏滚动条",
          add_time: "2021-04-10",
          tag: "Git",
          read_time: "2",
          words: "435",
          content:`<p>当我们移动端nav菜单很多时候，需要实现滚动效果。使用 <code>overflow-x: scroll</code> 会出现滚动条，可以使用以下方法将滚动条隐藏。</p>
<!-- more -->
<h2 id="方法">方法</h2>
<pre><code class="language-javascript hljs"> .scroll {
padding-bottom: <span class="hljs-number">10</span>px;
margin-bottom: <span class="hljs-number">-10</span>px;
}
</code></pre>
<h2 id="附案例">附案例</h2>
<h3 id="html">HTML</h3>
<pre><code class="language-javascript hljs">&lt;div id=<span class="hljs-string">"slider"</span> <span class="hljs-class"><span class="hljs-keyword">class</span></span>=<span class="hljs-string">"slider"</span>&gt;
<span class="xml"><span class="hljs-tag">&lt;<span class="hljs-name">nav</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"scroll"</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">a</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"control-item active"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"#"</span>&gt;</span>推荐<span class="hljs-tag">&lt;/<span class="hljs-name">a</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">a</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"control-item"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"#"</span>&gt;</span>热点<span class="hljs-tag">&lt;/<span class="hljs-name">a</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">a</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"control-item"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"#"</span>&gt;</span>北京<span class="hljs-tag">&lt;/<span class="hljs-name">a</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">a</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"control-item"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"#"</span>&gt;</span>社会<span class="hljs-tag">&lt;/<span class="hljs-name">a</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">a</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"control-item"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"#"</span>&gt;</span>娱乐<span class="hljs-tag">&lt;/<span class="hljs-name">a</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">a</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"control-item"</span> <span class="hljs-attr">href</span>=<span class="hljs-string">"#"</span>&gt;</span>科技<span class="hljs-tag">&lt;/<span class="hljs-name">a</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">nav</span>&gt;</span></span>
&lt;<span class="hljs-regexp">/div&gt;
</span></code></pre>
<h3 id="css">CSS</h3>
<pre><code class="language-javascript hljs">.slider {
<span class="hljs-attr">widows</span>: <span class="hljs-number">100</span>%;
line-height: <span class="hljs-number">40</span>px;
overflow: hidden;
}
.scroll {
overflow-x: scroll;
padding-bottom: <span class="hljs-number">10</span>px;
margin-bottom: <span class="hljs-number">-10</span>px;
white-space: nowrap;
}
.control-item {
<span class="hljs-attr">display</span>: inline-block;
width: <span class="hljs-number">100</span>px;
text-align: center;
}
</code></pre>`
        },
      ],
    };
  },
  created() {
    console.log(this.$route.query.id)
    this.id = this.$route.query.id
  },
};
</script>
<style lang="scss" scoped>
.main-container {
  border-radius: 5px;
  padding: 40px;
  line-height: 2;
}
</style>