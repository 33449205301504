import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/pulic.css'
import '@/assets/fonts/font-awesome.css'
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?2ba229aeafea5cbfeadc161a534c7c2a";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
createApp(App).use(store).use(router).mount('#app')
