<template>
  <div class="header">
    <h1 class="title c333">{{ title }}</h1>
    <div class="meta f12 c999">
      <span>
        <i class="fa fa-calendar-o"></i>
        发布于 {{ addTime }}
        <span class="divider">|</span>
      </span>
      <span>
        <i class="fa fa-folder-o"></i>
        分类于 <a href="" class="c666">{{ tag }}</a>
        <span class="divider">|</span>
      </span>
      <span>
        <i class="fa fa-clock-o"></i>
        2分钟
        <span class="divider">|</span>
      </span>
      <span>
        <i class="fa fa-file-word-o"></i>
        {{ words }}字数
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ["title", "addTime", "tag", "read_tim", "words"],
};
</script>
<style lang="scss" scoped>
.header {
  text-align: center;
  margin: 3px 0 60px;
  .title {
    margin: 20px 0 15px;
  }
  .divider {
    margin: 0 7px;
  }
}
</style>