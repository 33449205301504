<template>
  <div class="sidebar bg-color mr20">
    <div class="logo-container">
      <img src="@/assets/images/logo.png" class="logo" />
    </div>
    <div class="title">
      <span class="f14 c333 fb name">小黑</span>
      <p class="f12 c999">温故而知新</p>
    </div>
    <div class="nav flex flex-ac flex-pj c333">
      <div class="it1" v-for="item in navList" :key="item">
        <a href="javascript:void(0);">
          <span>{{ item.count }}</span>
          <p>{{ item.name }}</p>
        </a>
      </div>
    </div>
    <div class="social mt20">
      <a href="https://github.com/lbgs" class="social-item">
        <i class="fa fa-github-alt" title="github"></i>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "文章",
          count: 4,
        },
        {
          name: "分类",
          count: 4,
        },
        {
          name: "标签",
          count: 4,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
a:hover {
  color: #000;
}
.sidebar {
  position: sticky;
  top: 0;
  display: inline-table;
  width: 220px;
  padding: 20px 10px;
  margin-top: 326px;
  border-radius: 5px;
  text-align: center;
  .logo-container {
    .logo {
      max-width: 120px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #eee;
    }
  }
  .title {
    .name {
      line-height: 2;
    }
  }
  .nav {
    padding: 25px 20px 0;
  }
}
</style>