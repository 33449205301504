<template>
  <div id="nav">
    <Header />
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> -->
  </div>
  <router-view />
  <div class="bg-img">
    <img src="@/assets/images/nsz.jpg" />
  </div>
  <footer class="footer-box ft14 c999">©版权所有 2021 
    <a class="icp" target="_blank" href="http://beian.miit.gov.cn/">粤ICP备2021051646号</a>
  </footer>
</template>
<script>
import Header from "@/components/header.vue";

export default {
  name: "Home",
  components: {
    Header,
  },
};
</script>
<style lang="scss">
#app {
  width: 960px;
  height: 1500px;
  margin: 0 auto;
  // background-color: cyan;
}
.bg-img {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.85;
  img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }
}
body {
  background-color: #eee;
}
.footer-box {
  margin-top: 80px;
  min-height: 50px;
  text-align: center;
}
.icp{
  color: white;
}
.icp:hover{
 text-decoration: underline; 
}
</style>
